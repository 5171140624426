<template>
	<div class="container--center" v-if="computedValue === `ko`">
		<div class="form">
			<!--아이디-->
			<div class="form__group" id="input-margin">
				<label for="input-1">* 아이디</label>
				<div class="input--idbox">
					<input
						class="input--id"
						id="input-1"
						v-model="form.userId"
						placeholder="아이디(필수)"
						maxlength="12"
						type="text"
					/>
					<button
						class="btnDisabledDuplicate"
						type="button"
						@click="duplicatecheck()"
						:class="{ btnDuplicate: idState }"
						:disabled="!idState"
					>
						중복 확인
					</button>
				</div>

				<div v-show="idState == true" class="validateSucccess">
					중복 확인 버튼을 눌러주세요.
				</div>
				<div v-show="idState == false" class="validateWarning">
					아이디 형식을 확인해주세요.
				</div>

				<!--
			<div
				class="validateWarning"
				:class="{ validateSucccess: duplicateState }"
			>
				{{ this.message }}
			</div>
			-->
				<!-- 설명 -->
				<div class="form-guide">6~12자의 영문자, 숫자 사용 가능</div>
			</div>
			<!-- 비밀번호 -->
			<div class="form__group" id="input-margin">
				<label for="input-2">* 비밀번호</label>
				<input
					v-show="toggleOn"
					id="input-2"
					v-model="form.userPw"
					placeholder="비밀번호(필수)"
					maxlength="20"
					type="password"
				/>
				<input
					v-show="!toggleOn"
					id="input-2"
					v-model="form.userPw"
					placeholder="비밀번호(필수)"
					maxlength="20"
					type="text"
				/>
				<button class="btn__show-pw" @click="toggleClass">
					<i v-show="toggleOn" class="fa fa-eye fa-md show-pw"></i>
					<i v-show="!toggleOn" class="fa fa-eye-slash fa-md show-pw"></i>
				</button>

				<!-- 유효성 검사 알림 -->
				<div v-show="pwState == false" class="validateWarning">
					비밀번호 형식을 확인해주세요.
				</div>
				<div v-show="pwState == true" class="validateSucccess">
					사용 가능한 비밀번호 입니다.
				</div>

				<!-- 설명 -->
				<div class="form-guide">
					10~20자 영문자, 숫자, 특수문자(!@#$%^&*?)모두 포함
				</div>
			</div>

			<!--비밀번호 확인-->
			<div class="form__group" id="input-margin">
				<label for="input-3">* 비밀번호 확인</label>
				<input
					v-show="toggleOn2"
					id="input-3"
					v-model="form.pwCheck"
					placeholder="비밀번호 확인(필수)"
					maxlength="20"
					type="password"
				/>
				<input
					v-show="!toggleOn2"
					id="input-3"
					v-model="form.pwCheck"
					placeholder="비밀번호 확인(필수)"
					maxlength="20"
					type="text"
				/>
				<button class="btn__show-pw" @click="toggleClass2">
					<i v-show="toggleOn2" class="fa fa-eye fa-md show-pw"></i>
					<i v-show="!toggleOn2" class="fa fa-eye-slash fa-md show-pw"></i>
				</button>

				<!-- 유효성 검사 알림 -->
				<div v-show="pwCheck == false" class="validateWarning">
					비밀번호가 일치하지 않습니다.
				</div>
				<div v-show="pwCheck == true" class="validateSucccess">
					비밀번호가 일치합니다.
				</div>

				<!-- 설명 -->
				<div class="form-guide">
					10~20자 영문자, 숫자, 특수문자(!@#$%^&*?)모두 포함
				</div>
			</div>

			<!-- 전화번호 -->
			<div class="form__group" id="input-margin">
				<label for="input-5">* 전화번호</label>
				<input
					id="input-5"
					v-model="form.userPhone"
					:state="phoneState"
					placeholder="전화번호(필수)"
					maxlength="11"
					type="text"
					oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
				/>

				<!-- 유효성 검사 알림 -->
				<div v-show="this.phoneState == false" class="validateWarning">
					전화번호를 정확히 입력해주세요.
				</div>
				<div v-show="phoneState == true" class="validateSucccess">
					사용가능한 전화번호 입니다.
				</div>

				<!-- 설명 -->
				<div class="form-guide">-를 제외하고 입력해주세요.</div>
			</div>

			<!--이메일-->
			<div class="form__group" id="input-margin">
				<label for="input-6">* 이메일</label>
				<input
					id="input-6"
					v-model="form.userEmail"
					:state="emailState"
					placeholder="이메일(필수)"
					maxlength="60"
					type="text"
				/>

				<!-- 유효성 검사 알림 -->
				<div v-show="emailState == false" class="validateWarning">
					이메일 형식이 일치하지 않습니다.
				</div>
				<div v-show="emailState == true" class="validateSucccess">
					사용 가능한 이메일입니다.
				</div>

				<!-- 설명 -->
				<div class="form-guide">이메일 형식으로 입력해주세요.</div>
			</div>

			<!--이름-->
			<div class="form__group">
				<label for="input-4">이름</label>
				<input
					id="input-4"
					v-model="form.userName"
					type="text"
					required
					placeholder="이름"
					maxlength="20"
					oninput="this.value = this.value.replace(/^[0-9|\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\|]+$/, '').replace(/(\..*)\./g, '$1')"
				/>
			</div>

			<!--생년월일-->
			<div class="form__group">
				<label for="input-7">생년월일 </label>
				<date-picker
					id="input-7"
					v-model="form.userBirth"
					type="date"
					required
					placeholder="연도-월-일"
					:language="koLang"
				/>
			</div>

			<!--회사네임-->
			<div class="form__group">
				<label for="input-8"
					>기업 (선택)<span>*추천사가 있을 시 같이 입력해주세요.</span></label
				>
				<input
					id="input-8"
					v-model="form.userCompany"
					type="text"
					placeholder="회사 이름"
					maxlength="100"
				/>
			</div>

			<!--주소-->
			<div class="form__group">
				<label for="zipcode">주소</label>
				<div class="input--addressbox">
					<input
						class="input--zipcode"
						id="zipcode"
						v-model="form.zipCode"
						type="text"
						required
						disabled
						placeholder="우편번호"
					/>
					<button class="btn--zipcode" type="button" @click="addrSearch">
						우편번호<br />
						검색
					</button>
				</div>
				<p>
					<input
						id="address"
						v-model="form.address"
						type="text"
						required
						disabled
						placeholder="주소"
					/>
				</p>
				<p>
					<input
						id="addressExtra"
						v-model="form.addressExtra"
						type="text"
						required
						disabled
						placeholder="참고사항"
					/>
				</p>
				<p>
					<input
						id="addressDetail"
						v-model="form.addressDetail"
						type="text"
						placeholder="상세주소"
						maxlength="50"
					/>
				</p>
			</div>

			<!--약관동의-->
			<div class="agree__group">
				<input type="checkbox" id="checkbox-1" v-model="form.agree" />
				<router-link to="/terms" target="_blank">이용약관</router-link>
				<span> &nbsp;및&nbsp;</span>
				<router-link to="/privacy" target="_blank">
					개인정보 처리방침
				</router-link>
				<span>&nbsp;에 동의합니다.</span>
			</div>
			<button
				type="submit"
				class="btn__form"
				:class="{ btnDisabled: !allCheck }"
				:disabled="!allCheck"
				@click="submitForm()"
			>
				회원가입
			</button>

			<div id="linkLogin">
				<span>계정이 있으신가요?&nbsp;&nbsp;</span
				><router-link to="/login">로그인</router-link>
			</div>
		</div>
	</div>
	<div class="container--center" v-else-if="computedValue === `en`">
		<div class="form">
			<!--아이디-->
			<div class="form__group" id="input-margin">
				<label for="input-1">* Username</label>
				<div class="input--idbox">
					<input
						class="input--id"
						id="input-1"
						v-model="form.userId"
						placeholder="Username (Required)"
						maxlength="12"
						type="text"
					/>
					<button
						class="btnDisabledDuplicate"
						type="button"
						@click="duplicatecheck()"
						:class="{ btnDuplicate: idState }"
						:disabled="!idState"
					>
						Check for duplicates
					</button>
				</div>

				<div v-show="idState == true" class="validateSucccess">
					Please click the Check for Duplicates button.
				</div>
				<div v-show="idState == false" class="validateWarning">
					Please check the format of your username.
				</div>

				<!--
			<div
				class="validateWarning"
				:class="{ validateSucccess: duplicateState }"
			>
				{{ this.message }}
			</div>
			-->
				<!-- 설명 -->
				<div class="form-guide">
					6-12 characters: Letters and numbers allowed
				</div>
			</div>
			<!-- 비밀번호 -->
			<div class="form__group" id="input-margin">
				<label for="input-2">* Password</label>
				<input
					v-show="toggleOn"
					id="input-2"
					v-model="form.userPw"
					placeholder="Password (Required)"
					maxlength="20"
					type="password"
				/>
				<input
					v-show="!toggleOn"
					id="input-2"
					v-model="form.userPw"
					placeholder="Password (Required)"
					maxlength="20"
					type="text"
				/>
				<button class="btn__show-pw" @click="toggleClass">
					<i v-show="toggleOn" class="fa fa-eye fa-md show-pw"></i>
					<i v-show="!toggleOn" class="fa fa-eye-slash fa-md show-pw"></i>
				</button>

				<!-- 유효성 검사 알림 -->
				<div v-show="pwState == false" class="validateWarning">
					Please check the password format.
				</div>
				<div v-show="pwState == true" class="validateSucccess">
					This is a valid password.
				</div>

				<!-- 설명 -->
				<div class="form-guide">
					10-20 characters: Must include letters, numbers, and special
					characters (!@#$%^&*?)
				</div>
			</div>

			<!--비밀번호 확인-->
			<div class="form__group" id="input-margin">
				<label for="input-3">* Confirm Password</label>
				<input
					v-show="toggleOn2"
					id="input-3"
					v-model="form.pwCheck"
					placeholder="Confirm Password (Required)"
					maxlength="20"
					type="password"
				/>
				<input
					v-show="!toggleOn2"
					id="input-3"
					v-model="form.pwCheck"
					placeholder="Confirm Password (Required)"
					maxlength="20"
					type="text"
				/>
				<button class="btn__show-pw" @click="toggleClass2">
					<i v-show="toggleOn2" class="fa fa-eye fa-md show-pw"></i>
					<i v-show="!toggleOn2" class="fa fa-eye-slash fa-md show-pw"></i>
				</button>

				<!-- 유효성 검사 알림 -->
				<div v-show="pwCheck == false" class="validateWarning">
					The passwords do not match.
				</div>
				<div v-show="pwCheck == true" class="validateSucccess">
					The passwords match.
				</div>

				<!-- 설명 -->
				<div class="form-guide">
					10-20 characters: Must include letters, numbers, and special
					characters (!@#$%^&*?)
				</div>
			</div>

			<!-- 전화번호 -->
			<div class="form__group" id="input-margin">
				<label for="input-5">* Phone Number</label>
				<input
					id="input-5"
					v-model="form.userPhone"
					:state="phoneState"
					placeholder="Phone Number (Required)"
					maxlength="11"
					type="text"
					oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
				/>

				<!-- 유효성 검사 알림 -->
				<div v-show="this.phoneState == false" class="validateWarning">
					Please enter your phone number correctly.
				</div>
				<div v-show="phoneState == true" class="validateSucccess">
					This is a valid phone number.
				</div>

				<!-- 설명 -->
				<div class="form-guide">Enter without hyphens (-).</div>
			</div>

			<!--이메일-->
			<div class="form__group" id="input-margin">
				<label for="input-6">* Email</label>
				<input
					id="input-6"
					v-model="form.userEmail"
					:state="emailState"
					placeholder="Email (Required)"
					maxlength="60"
					type="text"
				/>

				<!-- 유효성 검사 알림 -->
				<div v-show="emailState == false" class="validateWarning">
					The email format is incorrect
				</div>
				<div v-show="emailState == true" class="validateSucccess">
					This is a valid email.
				</div>

				<!-- 설명 -->
				<div class="form-guide">Enter in email format.</div>
			</div>

			<!--이름-->
			<div class="form__group">
				<label for="input-4">Name</label>
				<input
					id="input-4"
					v-model="form.userName"
					type="text"
					required
					placeholder="Name"
					maxlength="20"
					oninput="this.value = this.value.replace(/^[0-9|\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\|]+$/, '').replace(/(\..*)\./g, '$1')"
				/>
			</div>

			<!--생년월일-->
			<div class="form__group">
				<label for="input-7">Date of Birth</label>
				<date-picker
					id="input-7"
					v-model="form.userBirth"
					type="date"
					required
					placeholder="YYYY-MM-DD"
					:language="enLang"
				/>
			</div>

			<!--회사네임-->
			<div class="form__group">
				<label for="input-8"
					>Company (Optional)<span
						>*If you have a referral, please enter it here.</span
					></label
				>
				<input
					id="input-8"
					v-model="form.userCompany"
					type="text"
					placeholder="Company Name"
					maxlength="100"
				/>
			</div>

			<!--주소-->
			<div class="form__group">
				<label for="zipcode">Address</label>
				<div class="input--addressbox">
					<input
						class="input--zipcode"
						id="zipcode"
						v-model="form.zipCode"
						type="text"
						required
						disabled
						placeholder="Postal Code"
					/>
					<button class="btn--zipcode" type="button" @click="addrSearch">
						Postal Code<br />
						Search
					</button>
				</div>
				<p>
					<input
						id="address"
						v-model="form.address"
						type="text"
						required
						disabled
						placeholder="Address"
					/>
				</p>
				<p>
					<input
						id="addressExtra"
						v-model="form.addressExtra"
						type="text"
						required
						disabled
						placeholder="Additional Information"
					/>
				</p>
				<p>
					<input
						id="addressDetail"
						v-model="form.addressDetail"
						type="text"
						placeholder="Detailed Address"
						maxlength="50"
					/>
				</p>
			</div>

			<!--약관동의-->
			<div class="agree__group">
				<input type="checkbox" id="checkbox-1" v-model="form.agree" />
				<span> &nbsp;I agree to the&nbsp;</span>
				<router-link to="/terms" target="_blank">Terms of Use</router-link>
				<span> &nbsp;and&nbsp;</span>
				<router-link to="/privacy" target="_blank">
					Privacy Policy
				</router-link>
			</div>
			<button
				type="submit"
				class="btn__form"
				:class="{ btnDisabled: !allCheck }"
				:disabled="!allCheck"
				@click="submitForm()"
			>
				Sign Up
			</button>

			<div id="linkLogin">
				<span>Already have an account?&nbsp;&nbsp;</span
				><router-link to="/login">Log in</router-link>
			</div>
		</div>
	</div>
</template>

<script src="//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"></script>
<script>
import { validateEmail } from '@/utils/validation';
// import { registerUser } from '@/api/index';
import { validateId } from '@/utils/validation';
import { validatePw } from '@/utils/validation';
import userinfoDataService from '../../services/userinfoDataService';
import DatePicker from 'vuejs-datepicker';
import { en, ko } from 'vuejs-datepicker/dist/locale';

export default {
	components: {
		DatePicker,
	},
	data() {
		return {
			form: {
				userId: '',
				userPw: '',
				pwCheck: '',
				userName: '',
				userPhone: '',
				userBirth: '',
				userEmail: '',
				userCompany: '',
				address: '',
				addressExtra: '',
				addressDetail: '',
				zipCode: '',
				agree: false,
			},
			duplicateState: null,
			message: '',
			toggleOn: true,
			toggleOn2: true,
			enLang: en,
			koLang: ko,
		};
	},
	// computed는 매번 자동으로 검사
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
		// 추후 중복확인 여부도 체크해야함
		/*
		...idGetters( {
			idFromStore: 'idState'
		}),
		idState: {
			get() {
				return this.idFromStore
			},
		},
		set(newIdstate) {
			return newIdstate
		}
	},*/
		idState() {
			if (this.form.userId != '') {
				return validateId(this.form.userId);
			} else {
				return null;
			}
		},
		pwState() {
			if (this.form.userPw != '') {
				return validatePw(this.form.userPw);
			} else {
				return null;
			}
		},
		pwCheck() {
			if (this.form.pwCheck.length == '') {
				return null;
			} else {
				if (this.form.pwCheck === this.form.userPw) {
					return true;
				} else {
					return false;
				}
			}
		},
		emailState() {
			if (this.form.userEmail != '') {
				return validateEmail(this.form.userEmail);
			} else {
				return null;
			}
		},
		phoneState() {
			if (this.form.userPhone.length == '') {
				return null;
			} else {
				if (this.form.userPhone.length >= 9) {
					return true;
				} else {
					return false;
				}
			}
		},
		// 회원가입 버튼 활성화 검사(회원가입 필수항목 유효성 검사 완료 시 버튼 활성화)
		// 아이디 중복확인 추가해야 함
		allCheck() {
			if (
				this.idState == true &&
				this.duplicateState == true &&
				this.pwState == true &&
				this.pwCheck == true &&
				this.phoneState == true &&
				this.emailState == true &&
				this.form.agree
			) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		toggleClass() {
			this.toggleOn = !this.toggleOn;
		},
		toggleClass2() {
			this.toggleOn2 = !this.toggleOn2;
		},
		// 다음 주소 검색 api
		addrSearch() {
			new daum.Postcode({
				oncomplete: data => {
					let addr = ''; // 주소 변수
					let extraAddr = ''; // 참고항목 변수

					// 도로명 주소 선택한 경우(1)
					if (data.userSelectedType === 'R') {
						addr = data.roadAddress;

						// 지번 주소 선택한 경우
					} else {
						addr = data.jibunAddress;
					}

					// 도로명 주소 선택한 경우(2) -> 참고항목 조합
					if (data.userSelectedType === 'R') {
						// 법정동명 있을 경우(법정리는 제외 / 법정동은 마지막 문자가 동로가로 끝남)
						if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
							extraAddr += data.bname;
						} // 건물명이 있고 공동주택일 경우
						if (data.buildingName !== '' && data.apartment === 'Y') {
							extraAddr +=
								extraAddr !== '' ? ',' + data.buildingName : data.buildingName;
						}
						if (extraAddr !== '') {
							extraAddr = '(' + extraAddr + ')';
						}
						this.form.addressExtra = extraAddr;
					} else {
						this.form.addressExtra = '';
					}
					this.form.zipCode = data.zonecode;
					this.form.address = addr;
					// 커서를 상세주소 필드로 이동
					document.getElementById('addressDetail').focus();
				},
			}).open();
		},
		//isUseridValid() {
		//	var re = /^[A-Za-z0-9]{6,12}$/;
		//	if (re.test(this.form.userId)) {
		//		this.form.showIdAlert = true;
		//		this.form.idAlert = '아이디 형식이 맞지 않습니다.';
		//	} else {
		//		this.form.showIdAlert = false;
		//	}
		//},

		//데이터 전송
		async submitForm() {
			console.log('폼 제출');

			const userData = {
				userID: this.form.userId,
				userPW: this.form.userPw,
				userPhone: this.form.userPhone,
				userEmail: this.form.userEmail,
				userName: this.form.userName,
				userBirth: this.form.userBirth,
				userCompany: this.form.userCompany,
				address: this.form.address,
				addressExtra: this.form.addressExtra,
				addressDetail: this.form.addressDetail,
				addressZipCode: this.form.zipCode,
			};
			userinfoDataService.signup(userData).then(res => {
				console.log('Success insert data');
				console.log(res.data);
				// 회원정보 스토어에 저장
			});
			// 회원가입 완료 페이지로 ID 값 보내는 이벤트 버스
			let id = this.form.userId;
			this.$emit('setId', id);
			this.$router.push('./signup/complete');
		},
		// 아이디 중복 확인

		async duplicatecheck() {
			//중복확인 userinfoDataService
			let valiIdCheck = false;

			if (this.form.userId != '') {
				valiIdCheck = validateId(this.form.userId);
			}
			if (valiIdCheck == true) {
				//중복확인 userinfoDataService`
				let userId = this.form.userId;

				userinfoDataService
					.getSameID(userId)
					.then(res => {
						console.log('중복검사완료');
						console.log(res.data);
						// 중복된 아이디인 경우
						if (res.data) {
							this.duplicateState = false;
							console.log('중복된 아이디');
							this.message = '이미 사용중인 아이디입니다.';
							alert(
								'이미 사용중인 아이디입니다.\n(This useid is already in use.)',
							);
							this.idState = null;
							// 사용 가능한 아이디인 경우
						} else if (!res.data) {
							this.duplicateState = true;
							console.log('사용 가능한 아이디');
							this.message = '사용 가능한 아이디입니다.';
							alert('사용 가능한 아이디입니다.\n(This is a valid usename.)');
						}
					})
					.catch(e => {
						console.log(e);
					});
			}
		},
	},
};
</script>

<style></style>
